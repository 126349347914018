import * as React from "react";
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import VpnKeyRoundedIcon from "@material-ui/icons/VpnKeyRounded";
import AuthorizationUtil from "./util/AuthorizationUtil";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import {withRouter} from "react-router-dom";

class AccountButton extends React.Component {
    auth = new AuthorizationUtil();

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            surname: ""
        }
    }

    loadData = async () => {
        this.auth.getOwnName().then((userName) => this.setState({name: userName.name, surname: userName.surname}));
    };

    componentDidMount() {
        this.loadData()

    }

    render() {
        return (<div>
            <Chip className={"Chip"} onClick={this.props.onClick} size="medium"
                  avatar={<Avatar><AccountCircle className={"UserIcon"}/></Avatar>}
                  label={this.state.name + " " + this.state.surname} color="primary">
            </Chip>
            <Menu
                id="menu-appbar"
                anchorEl={this.props.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(this.props.anchorEl)}
                onClose={this.props.onClose}
            >
                <MenuItem onClick={this.props.onClose && this.props.onLogout}>Logout &nbsp;
                    <ExitToAppIcon/></MenuItem>
                <MenuItem onClick={() => this.props.history.push("/newpassword")}>Passwort ändern &nbsp;
                    < VpnKeyRoundedIcon/></MenuItem>
            </Menu>
        </div>);
    }
}

export default withRouter(AccountButton)