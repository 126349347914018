import * as React from "react";
import Grid from "@material-ui/core/Grid";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Paper from "@material-ui/core/Paper";
import AuthorizationUtil from "./util/AuthorizationUtil";
import Button from "@material-ui/core/Button";
import LoadContainer from "./LoadContainer";
import axios from "axios";
import {getIP} from "./Config/config";

class AddCorrectionDialogListEntry extends React.Component {

    auth = new AuthorizationUtil();
    token = this.auth.token;
    header = {"Authorization": `Bearer ` + this.token};

    constructor(props) {
        super(props);
        this.state = {
            date: null,
            time: null,
            dateErrorText: "",
            visible: true,
            loading: false,
        };
    }

    handleDateChange = name => date => {
        this.setState({dateErrorText: ""});
        this.setState({
            [name]: date
        });
    };

    handleSubmit = () => {
        this.setState({loading: true});
        let timeStamp = new Date(this.state.date.getFullYear(), this.state.date.getMonth(), this.state.date.getDate(), this.state.time.getHours(), this.state.time.getMinutes(), this.state.time.getSeconds());
        let oldTime = new Date(this.props.date);
        timeStamp.toString();
        oldTime.toString();
        const chipID = this.props.chipID;
        axios.post(getIP()+'/correct', {timestamp: timeStamp, oldTime: oldTime, chipID: chipID}, {headers: this.header})
            .then ((response) => {
                this.setState({loading: false});
                this.setState({visible: false});
            })
            .catch((error) => {
                this.setState({loading: false});
                console.log(error)
            })

    }


    render() {
        const valid = this.state.date && this.state.time;
        const submitButton = <Button variant="contained" color="primary" disabled={!valid} onClick={this.handleSubmit}>
            Korrigieren
        </Button>;
        return (
            this.state.visible && <div>
                <Paper>
                    <Grid container spacing={3} alignItems={"center"}
                          justify={"space-evenly"}>
                        <Grid item>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disabled
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label={"Automatisches Datum"}
                                        format="dd/MM/yyyy"
                                        value={this.props.date}
                                        onChange={this.handleDateChange("date")}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    <KeyboardTimePicker
                                        disabled
                                        margin="normal"
                                        id="time-picker"
                                        label={"Automatischer Zeitpunkt"}
                                        value={this.props.date}
                                        onChange={this.handleDateChange("time")}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    required="true"
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={"Korrigiertes Datum"}
                                    format="dd/MM/yyyy"
                                    value={this.state.date}
                                    onChange={this.handleDateChange("date")}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardTimePicker
                                    required="true"
                                    margin="normal"
                                    id="time-picker"
                                    label={" Korrigierter Zeitpunkt"}
                                    value={this.state.time}
                                    onChange={this.handleDateChange("time")}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item>
                            <LoadContainer loading={this.state.loading} component={submitButton}/>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        )
    }

}

export default AddCorrectionDialogListEntry