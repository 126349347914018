import React, { Component } from 'react'
import formatDate from '../../util/formatDate';
import formatDuration from '../../util/formatDuration';
import { ListItem, Paper, Grid, Typography } from '@material-ui/core';
import formatOnlyTime from '../../util/formatOnlyTime';
import HolidayUtil from "../../util/HolidayUtil";
export default class ResultListElement extends Component {


    render() {
        var result = this.props.result;
        var color = result.overTime >= 0 ? '#89d185' : '#cc6633';
        var workingTime = formatOnlyTime(new Date(result.first)) + " - " + formatOnlyTime(new Date(result.last));
        var overtime = "Überstunden: " + formatDuration(result.overTime)


        if(result.isHoliday){
            color = '#53599a';
            workingTime = "";
            overtime = result.holidayTitle;
        } else if (new Date(result.first).getTime() < 100) {
            color = '#96999c';
            workingTime = "";
            overtime = "Keine Daten"
        }
        else if (new Date(result.last).getTime() < 100){
            color = '#ffa500';
            workingTime = formatOnlyTime(new Date(result.first)) + " - aktuell";
            overtime = ""
            
        }

        return (
            <ListItem>
                <Paper elevation="0" style={{ backgroundColor: color, minWidth: '700px', padding: '15px', textAlign: 'center' }} >
                    <Grid
                        justify="space-between"
                        container
                        spacing={10}
                    >
                        <Grid item>

                            <Grid container justify="column" spacing="5" alignItems="center">
                                <Grid item> <Typography variant="h5" style={{ color: '#fff' }}>{formatDate(new Date(result.date))} </Typography></Grid>
                                <Grid item> <Typography variant="h5" style={{ color: '#fff' }}>{workingTime} </Typography></Grid>
                                <Grid item> <Typography variant="h5" style={{ color: '#fff' }}>{overtime} </Typography></Grid>
                            </Grid>
                        </Grid></Grid>


                </Paper>
            </ListItem>
        )
    }
}
