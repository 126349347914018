import React, {Component} from 'react'
import DatePicker from './DatePicker'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {getIP} from '../../Config/config'
import AuthorizationUtil from "../../util/AuthorizationUtil";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import FormHelperText from "@material-ui/core/FormHelperText";
import isAfter from 'date-fns/isAfter';

export default class SearchBar extends Component {

    auth = new AuthorizationUtil();

    state = {
        inferiors: [],
        mID: '',
        dateVon: Date.now(),
        dateBis: Date.now(),
        dateErrorText: "",
        midErrorText: "",
        open: false
    }

    handleClose = () => {
        this.setState({open: false})
    };

    handleOpen = () => {
        this.setState({open: true})
    };

    handleChange = (e) => this.setState({[e.target.name]: e.target.value});

    handleMIDChange = (e) => {
        this.setState({midErrorText: ""});
        this.setState({[e.target.name]: e.target.value})
    };

    handleChangeDate = name => date => {
        this.setState({dateErrorText: ""});
        this.setState({[name]: date})
    }

    fetchData = () => {
        if (this.state.mID === "") {
            this.setState({midErrorText: "Es muss ein Mitarbeiter ausgewählt werden"});
            return
        };
        if (isAfter(new Date(this.state.dateVon), new Date(this.state.dateBis))) {
            this.setState({dateErrorText: "Das Startdatum darf nicht nach dem Enddatum sein!"});
            return
        }
        ;
        var von = new Date(this.state.dateVon);
        var bis = new Date(this.state.dateBis);
        bis.setDate(bis.getDate() + 1);
        var start = von.getUTCFullYear() + "-" + (von.getMonth() + 1) + "-" + von.getDate();
        var end = bis.getUTCFullYear() + "-" + (bis.getMonth() + 1) + "-" + bis.getDate();
        var xhr = new XMLHttpRequest()
        xhr.addEventListener('load', () => {
            this.props.handleFetch(xhr.response);
        })
        xhr.open('POST', getIP() + '/report')
        //TODO: aus state holen
        xhr.setRequestHeader("Authorization", `Bearer ` + this.auth.token);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify({"mID": this.state.mID, "start": start, "end": end}));
    };

    loadData = async () => {
        const inferiors = await this.auth.getInferiors();
        this.setState({inferiors: inferiors});
    };


    componentDidMount() {
        this.loadData();
    }

    render() {
        const menuItems = this.state.inferiors.map((inferior) =>
            <MenuItem value={inferior.mID}>{inferior.Vorname + " " + inferior.Nachname}</MenuItem>);
        return (
            <div style={{display: 'flex'}}>
                <Paper style={{width: '1000px', minHeight: '15vh', padding: '15px', margin: '20px'}}>
                    <Grid container spacing={10} alignItems="center" justify="space-around">
                        <Grid item>
                        </Grid>
                        <FormControl error={Boolean(this.state.midErrorText)}>
                            <InputLabel id="select-label">Mitarbeiter</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                name="mID"
                                style={{
                                    margin: 20,
                                    minWidth: 120,
                                }}
                                open={this.open}
                                onClose={this.handleClose}
                                onOpen={this.handleOpen}
                                value={this.state.mID}
                                onChange={this.handleMIDChange}
                            >
                                {menuItems}

                            </Select>
                            {(this.state.midErrorText && <FormHelperText> {this.state.midErrorText}</FormHelperText>)}
                        </FormControl>
                        <Grid item>
                            <DatePicker error={Boolean(this.state.dateErrorText)}
                                        helperText={this.state.dateErrorText}
                                        label="Von" name="dateVon" handleChange={this.handleChangeDate}
                                        val={this.state.dateVon}/>
                        </Grid>
                        <Grid item>
                            <DatePicker label="Bis" name="dateBis" handleChange={this.handleChangeDate}
                                        val={this.state.dateBis}/>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={this.fetchData}>
                                <SearchIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        )
    }
}
