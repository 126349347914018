import React, { Component } from 'react'
import { TextField } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import formatDuration from '../../util/formatDuration';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from "@material-ui/core/Paper";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";

export default class WorkerListItem extends Component {

  state = {
    open: false,
    min: 0,
    hours: 0
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleZero = () => {
    var z = this.props.data.Zeitkonto;
    var h = z > 0 ? Math.floor(z) : Math.ceil(z);
    var min = (z - h) * 60;
    min = min > 0 ? Math.floor(min) : Math.ceil(min);
    this.setState({
      hours: h,
      min: min
    });
  }

  handleUpdateClick = () => {
    this.setState({ open: false });
    this.props.handleUpdate(this.props.data.MitarbeiterID, this.state.hours, this.state.min)
  }
  
  handleMinChange = (e) => {
    var newVal = e.target.value;
    if (Math.abs(newVal) > 59) newVal = 0;
    this.setState({ min: newVal })
  }

  
  render() {
    var color = this.props.data.Zeitkonto >= 0 ? '#89d185' : '#cc6633';
    const fullName = this.props.data.Vorname + " " + this.props.data.Nachname;
    return (
      <div>
        <ListItem>
          <Paper elevation="0" style={{backgroundColor: '#fff', minWidth: '700px', padding: '1px', textAlign: 'center'}} >
            <Grid container alignItems={"center"}
                  justify={"space-evenly"}>
              <Grid item style={{width:'80px'}}>
            <Chip className={"Chip"}  size="big" avatar={<Avatar><AccountCircle className={"UserIcon"} /></Avatar>} label={fullName} color="primary">
            </Chip>
              </Grid>
              <Grid item style={{width:'80px'}}>
            <Chip label={formatDuration(this.props.data.Zeitkonto * 3600)} color="primary" style={{backgroundColor: color}}/>
              </Grid>
              <Grid item style={{width:'80px'}}>
            <IconButton aria-label="renew" style={{ color: "#1e38a1" }} onClick={this.handleClickOpen}>
              <AutorenewIcon />
            </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </ListItem>
        {this._renderDialog()}
      </div>
    )
  }

  _renderDialog = () => {
    return (<Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Überstunden anpassen</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Der hier eingegebene Wert wird vom entsprechenden Überstundenkonto abgezogen (negative Werte werden aufaddiert)
  </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="hours"
          label="Stunden"
          type="number"
          value={this.state.hours}
          onChange={e => this.setState({ hours: e.target.value })}
        />
        <TextField
          autoFocus
          margin="dense"
          id="minutes"
          label="Minuten"
          type="number"
          value={this.state.min}
          onChange={this.handleMinChange}
        />
        <Button onClick={this.handleZero} color="primary">  Ausgleichen </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleClose} color="primary">
          Abbrechen
        </Button>
        <Button onClick={this.handleUpdateClick} color="primary">
          Aktualisieren
        </Button>
      </DialogActions>
    </Dialog>)
  }
}
