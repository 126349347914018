import React from 'react';
import './App.css';
import './main.scss'
import {BrowserRouter, Route, Switch} from 'react-router-dom'


import AdminPanel from './Dashboard/AdminPage/AdminPanel';
import OverviewPage from './Dashboard/OverviewPage/OverviewPage';
import OvertimePage from './Dashboard/OvertimePage/OvertimePage';
import CorrectionPage from './Dashboard/CorrectionPage/CorrectionPage';
import Navbar from "./Navbar";
import Login from './Login'
import Notfound from "./404";
import NewPassword from "./NewPassword"
import DatenschutzPage from './DatenschutzPage';
import CalendarPage from "./CalendarPage";
import AbsencePage from "./Dashboard/AbsencePage/AbsencePage";
import AuthorizationUtil from "./util/AuthorizationUtil";
import {PrivateRoute} from "./util/PrivateRoute";
import ReportPage from "./Dashboard/ReportPage/ReportPage";


class App extends React.Component {

    //TODO: in state packen ?

    auth = new AuthorizationUtil();
    token = this.auth.token;
    isLoggedIn = this.auth.isLoggedIn;

    render() {
        return (
            <BrowserRouter>
                <div className="App">
                    <Navbar isLoggedIn={this.isLoggedIn}/>
                    <Switch>
                        <Route path="/login" component={Login}/>
                        <PrivateRoute exact path={"/"} component={CalendarPage}/>
                        <PrivateRoute path="/admin" component={AdminPanel}/>
                        <PrivateRoute path="/overview" component={OverviewPage}/>
                        <PrivateRoute path="/overtime" component={OvertimePage}/>
                        <PrivateRoute path="/report" component={ReportPage}/>
                        <PrivateRoute path="/nachtrag" component={CorrectionPage}/>
                        <PrivateRoute path="/absence" component={AbsencePage}/>
                        <PrivateRoute path="/newpassword" component={NewPassword}/>
                        <Route path="/datenschutz" component={DatenschutzPage}/>

                        <Route component={Notfound}/>
                    </Switch>
                    <a href='/datenschutz'>Datenschutz</a>
                    <br/>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
