import React, { Component } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import de from 'date-fns/locale/de'

export default class DatePicker extends Component {

  state = {
    von: Date.now()
  }


  render() {
    return (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker"
            error={this.props.error}
            helperText={this.props.helperText}
            label={this.props.label}
            name={this.props.name}
            value={this.props.val}
            onChange={this.props.handleChange(this.props.name)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    )
  }
}
