import axios from "axios";
import {getIP} from "../Config/config";

class HolidayUtil {
    token = window.localStorage.getItem("token");
    header = {"Authorization": `Bearer ` + this.token};

    getHolidaysForYear(year){
        return axios.post(getIP() + '/getHolidaysForYear/', {CC: "DE", RC: "MV", year: year},{headers: this.header})
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log(error)
            });
    }

    getHolidays(start, end){
        return axios.post(getIP() + '/getHolidays/', {CC: "DE", RC: "MV", start: start, end: end},{headers: this.header})
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log(error)
            });

    }

    isDateHolidayInState(date){
        return axios.post(getIP() + '/isHoliday/', {CC: "DE", RC: "MV", date: date},{headers: this.header})
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log(error)
            });
    }

}
export default HolidayUtil