import React, { Component } from 'react'
import { CardHeader, CardContent } from "@material-ui/core";
import formatDuration from '../../util/formatDuration';
import Paper from "@material-ui/core/Paper";

export default class ResultCard extends Component {
  render() {
    var title = 'Gesamt: (' + this.props.result.first + " - " +  this.props.result.last +")";
    return (
      <div>
        <Paper elevation="0" style={{minWidth:'1000px', color: '#fff', backgroundColor: '#016abf'}}>
          <CardHeader title={title} />
          <CardContent>
            Zeit-Differenz: {formatDuration(this.props.result.overTime)}
          </CardContent>
        </Paper>
      </div>
    )
  }
}
