import React, {Component} from 'react'
import {Document, Page, PDFViewer, Text, View, StyleSheet} from "@react-pdf/renderer";
import format from "date-fns/format";
import addDays from "date-fns/addDays"
import HolidayUtil from "../../util/HolidayUtil";
import moment from "moment";

export default class ReportComponent extends Component {

    holidayUtil = new HolidayUtil();

    render() {
        const styles = StyleSheet.create({
            page: {
                flexDirection: 'row',
                backgroundColor: '#E4E4E4'
            },
            section: {
                margin: 10,
                padding: 10,
                flexGrow: 1
            },

            table: {
                display: "table",
                marginLeft: "auto",
                marginRight: "auto",
                width: "auto",
                borderStyle: "solid",
                borderWidth: 0,
                borderRightWidth: 0,
                borderBottomWidth: 0
            },
            tableRow: {
                marginLeft: "auto",
                marginRight: "auto",
                flexDirection: "row"
            },
            tableCol: {
                width: "14%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 1,
                borderTopWidth: 1
            },
            tableCol2: {
                width: "24.5%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 1,
                borderTopWidth: 1
            },
            tableCol3: {
                width: "49%",
                borderStyle: "solid",
                borderWidth: 1,
                borderLeftWidth: 1,
                borderTopWidth: 1
            },
            tableCell: {
                margin: "auto",
                marginTop: 5,
                fontSize: 10
            },
            tableText: {
                width: '98%',
                marginLeft: "auto",
                marginRight: "auto",
                flexDirection: "row",
                fontSize: 10,
                marginBottom: 5,

            },
            whiteSpace: {
                color: '#E4E4E4'
            }
        });

        const PlaceholderDocument = () => (<Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text>Bitte wählen sie einen Mitarbeiter und einen Zeitraum aus, um einen Report zu
                            erstellen.</Text>
                    </View>
                </View>
            </Page>
        </Document>);

        const ShiftsStartCells = (props) => (
            props.shifts.map((shift) => {
                return <Text style={styles.tableCell}>
                    {shift.start ? format(new Date(shift.start), 'dd.MM. HH:mm') : "N/A"}
                </Text>
            })
        );

        const ShiftsEndCells = (props) => (
            props.shifts.map((shift) => {
                return <Text style={styles.tableCell}>
                    {shift.end ? format(new Date(shift.end), 'dd.MM. HH:mm') : "N/A"}
                </Text>

            })
        );

        const MyDocument = () => (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View>
                                <Text>Stundenzettel: {this.props.workerData && this.props.workerData.worker[0].Vorname} {this.props.workerData && this.props.workerData.worker[0].Nachname} {format(new Date(this.props.workerData && this.props.workerData.start), 'dd.MM.yyyy')} - {format(addDays(new Date(this.props.workerData && this.props.workerData.end), -1), 'dd.MM.yyyy')}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.whiteSpace}>{"Placeholder"}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Datum</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Schichtbeginn</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Schichtende</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Anwesenheit</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text
                                    style={styles.tableCell}>{this.props.workerData && this.props.workerData.worker[0].isZeitarbeiter !== 1 ? "Überstunden" : "Arbeitszeit"}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Abwesenheit</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Pausenzeit</Text>
                            </View>
                        </View>

                        {this.props.report[0] && this.props.report[0].work.map((entry) => {
                            return <View key="Report-View" style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {entry.isHoliday ? entry.date ? format(new Date(entry.date), 'dd.MM.yyyy*') : "N/A" : entry.date ? format(new Date(entry.date), 'dd.MM.yyyy') : "N/A"}
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    {entry.shifts.length > 0 ?
                                        <ShiftsStartCells shifts={entry.shifts}/>
                                        : <Text style={styles.tableCell}>
                                            {"N/A"}
                                        </Text>}
                                </View>
                                <View style={styles.tableCol}>
                                    {entry.shifts.length > 0 ?
                                        <ShiftsEndCells shifts={entry.shifts}/>
                                        : <Text style={styles.tableCell}>
                                            {"N/A"}
                                        </Text>}
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {entry.timeWorked ? parseInt(entry.timeWorked / 3600) + " h " + parseInt((entry.timeWorked % 3600) / 60) + " min" : "N/A"}
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {entry.overTime ? (parseInt(entry.overTime / 3600) + " h " + parseInt((entry.overTime % 3600) / 60) + " min") : "N/A"}
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {entry.absence ? ((parseInt(entry.absence % 3600) >= 0) ? parseInt(entry.absence / 3600) + " h " + parseInt((entry.absence % 3600) / 60) + " min" : 0) : "N/A"}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {entry.first && entry.last && entry.breakTime ? ((parseInt(entry.breakTime / 60) > 0) ? parseInt(entry.breakTime / 60) + " min " : 0 + " min ") : "N/A"}
                                    </Text>
                                </View>
                            </View>
                        })}
                        <View style={styles.tableRow}>
                            <Text style={styles.whiteSpace}>{"Placeholder"}</Text>
                        </View>
                        <View style={styles.tableText}>
                            {/*<Text>
                                Legende: * = Datum ist ein Feiertag (Feiertage sind in der Feiertagsübersicht aufgelistet)
                            </Text>*/}
                        </View>
                    </View>
                </Page>
                {Boolean(this.props.report[1] && this.props.report[1].absences.length > 0) && <
                    Page
                    size="A4"
                    style={styles.page}>
                    < View
                        style={styles.table}>
                        < View
                            style={styles.tableRow}>
                            < View>
                                < Text> Abwesenheitsübersicht
                                    : {this.props.workerData && this.props.workerData.worker[0].Vorname} {this.props.workerData && this.props.workerData.worker[0].Nachname} {format(new Date(this.props.workerData && this.props.workerData.start), 'dd.MM.yyyy')} - {format(addDays(new Date(this.props.workerData && this.props.workerData.end), -1), 'dd.MM.yyyy')
                                    }</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.whiteSpace}>{"Placeholder"}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol2}>
                                <Text style={styles.tableCell}>Datum</Text>
                            </View>
                            <View style={styles.tableCol2}>
                                <Text style={styles.tableCell}>Beginn</Text>
                            </View>
                            <View style={styles.tableCol2}>
                                <Text style={styles.tableCell}>Ende</Text>
                            </View>
                            <View style={styles.tableCol2}>
                                <Text style={styles.tableCell}>Grund</Text>
                            </View>
                        </View>

                        {this.props.report[1] && this.props.report[1].absences.map((entry) => {
                            return <View style={styles.tableRow}>
                                <View style={styles.tableCol2}>
                                    <Text style={styles.tableCell}>
                                        {entry.date ? format(new Date(entry.date), 'dd.MM.yyyy') : "N/A"}
                                    </Text>
                                </View>
                                <View style={styles.tableCol2}>
                                    <Text style={styles.tableCell}>
                                        {entry.start ? format(new Date(entry.start), 'HH:mm') : "N/A"}
                                    </Text>
                                </View>
                                <View style={styles.tableCol2}>
                                    <Text style={styles.tableCell}>
                                        {entry.end ? format(new Date(entry.end), 'HH:mm') : "N/A"}
                                    </Text>
                                </View>
                                <View style={styles.tableCol2}>
                                    <Text style={styles.tableCell}>
                                        {entry.reason ? entry.reason : "N/A"}
                                    </Text>
                                </View>

                            </View>
                        })}
                    </View>
                </Page>}
                {Boolean(this.props.holidays && this.props.holidays.length > 0) && <Page
                    size="A4"
                    style={styles.page}>
                    < View
                        style={styles.table}>
                        < View
                            style={styles.tableRow}>
                            < View>
                                < Text> Feiertagsübersicht
                                    : {this.props.workerData && this.props.workerData.worker[0].Vorname} {this.props.workerData && this.props.workerData.worker[0].Nachname} {format(new Date(this.props.workerData && this.props.workerData.start), 'dd.MM.yyyy')} - {format(addDays(new Date(this.props.workerData && this.props.workerData.end), -1), 'dd.MM.yyyy'
                                    )
                                    }</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.whiteSpace}>{"Placeholder"}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol3}>
                                <Text style={styles.tableCell}>Datum</Text>
                            </View>
                            <View style={styles.tableCol3}>
                                <Text style={styles.tableCell}>Feiertag</Text>
                            </View>
                        </View>

                        {this.props.holidays && this.props.holidays.map((entry) => {
                            return <View style={styles.tableRow}>
                                <View style={styles.tableCol3}>
                                    <Text style={styles.tableCell}>
                                        {entry.resolvedDate ? format(new Date(entry.resolvedDate), 'dd.MM.yyyy') : "N/A"}
                                    </Text>
                                </View>
                                <View style={styles.tableCol3}>
                                    <Text style={styles.tableCell}>
                                        {entry.holidayDefinition ? entry.holidayDefinition._name : "N/A"}
                                    </Text>
                                </View>
                            </View>
                        })}
                    </View>
                </Page>}
            </Document>);

        return (
            <div>
                <PDFViewer width={1000} height={1000}>
                    {this.props.report[0] && this.props.report[0].work ? <MyDocument/> : <PlaceholderDocument/>}
                </PDFViewer>
            </div>
        )
    }

}