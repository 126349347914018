import * as React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

class LoadContainer extends React.Component {
    render(){
        if (!this.props.loading) return (this.props.component);
        return(
            <div width="100%">
            <CircularProgress/>
            </div>
        );
    }

}
export default LoadContainer