import React, { Component } from 'react'
import CalendarComponent from "./CalendarComponent";
import AuthorizationUtil from "./util/AuthorizationUtil";
export default class CalendarPage extends Component {
    auth = new AuthorizationUtil();
    render() {return(<div>
        <CalendarComponent token={this.auth.token}/>
    </div>)
    }

}