import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import AuthorizationUtil from "./util/AuthorizationUtil";
import AddCorrectionDialogListEntry from "./AddCorrectionDialogListEntry";

class AddCorrectionDialog extends React.Component {

    auth = new AuthorizationUtil();

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            oneEntry: false,
            date: null,
            dateErrorText: "",
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null,
        };
    }
    handleClose = () => {
        this.setState({
            startDate: null,
            endDate: null,
            startTime: null,
            endTime: null,
            dateErrorText: "",
            oneEntry: null
        });
        this.props.onClose();
    };

    render() {
        return (<div>
            <Dialog open={this.props.open} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Stempel nachtragen️</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte die Ausstempeldaten für folgenden Schichten korrigieren.
                    </DialogContentText>
                    <Grid container>
                        <Grid item>
                            <Grid container style={{padding:'5px'}} spacing={3} alignItems={"center"}
                                  justify={"space-evenly"}>
                                {this.props.corrections.map((correction) => <Grid item><AddCorrectionDialogListEntry date={correction.TrackTime} chipID={correction.ChipID}/></Grid>)}
                            </Grid>
                            </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>)
    }

}

export default AddCorrectionDialog