import React, { Component } from 'react'
import { Card, CardHeader, CardContent } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import formatTimeStamp from '../../util/formatTimeStamp';
import AuthorizationUtil from "../../util/AuthorizationUtil";

export default class CorrectionListItem extends Component {

  auth = new AuthorizationUtil();

  state = {
    username: ""
  };

  componentDidMount() {
    this.loadData();
  };

  loadData = async () => {
    const data = await this.auth.resolveRelevantUserData(this.props.data.MitarbeiterID);
    this.setState({username: data[0].Vorname + " " + data[0].Nachname})
  };

  render() {
    return (
      <div>
        <Card>
          <CardHeader title={this.state.username || ""} style={{color: '#fff', backgroundColor: '#016abf'}}/>
          <CardContent>
            {formatTimeStamp(this.props.data.TrackTime)}
            <IconButton aria-label="delete" style={{ color:"#d24747"}} onClick={this.props.handleClick(false, this.props.data.ChipID, this.props.data.TrackTime)}>
              <DeleteIcon />
            </IconButton>
            <IconButton aria-label="check"style={{ color:"#008000" }} onClick={this.props.handleClick(true, this.props.data.ChipID, this.props.data.TrackTime)}>
              <CheckIcon />
            </IconButton>
          </CardContent>
        </Card>
      </div>
    )
  }
}
