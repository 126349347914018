import {Card, CardContent, CardHeader} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import React, {Component} from 'react'
import CardActions from "@material-ui/core/CardActions";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import AuthorizationUtil from "../../util/AuthorizationUtil";
import de from 'date-fns/locale/de'
import Grid from "@material-ui/core/Grid";

export default class AbsenceListItem extends Component {

    auth = new AuthorizationUtil();

    state = {
        username: ""
    };

    componentDidMount() {
        this.loadData();
    };

    loadData = async () => {
        const data = await this.auth.resolveRelevantUserData(this.props.data.MitarbeiterID);
        this.setState({username: data[0].Vorname + " " + data[0].Nachname})
    };

    render() {
        return (
            <div>
                <Card style={{minWidth: "600px"}}>
                    <CardHeader title={this.state.username || ""} style={{color: '#fff', backgroundColor: '#016abf'}}/>
                    <CardContent>
                        <Grid container direction="column">
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                                    <KeyboardDatePicker
                                        disabled
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label={"Startdatum"}
                                        format="dd/MM/yyyy"
                                        value={this.props.data.start}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    <KeyboardTimePicker
                                        disabled
                                        margin="normal"
                                        id="time-picker"
                                        label={"Startzeitpunkt"}
                                        value={this.props.data.start}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                                    <KeyboardDatePicker
                                        disabled
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label={"Enddatum"}
                                        format="dd/MM/yyyy"
                                        value={this.props.data.ende}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    <KeyboardTimePicker
                                        disabled
                                        margin="normal"
                                        id="time-picker"
                                        label={"Endzeitpunkt"}
                                        value={this.props.data.ende}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justify="space-around" spacing={10}>
                                    <Grid item>
                                        <Typography variant="h5">
                                            Grund :
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Chip label={this.props.data.grund}
                                              style={{color: '#fff', backgroundColor: '#016abf'}}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </CardContent>
                    <CardActions>
                        <Grid container justify="flex-end">
                            <IconButton aria-label="delete" style={{color: "#d24747"}}
                                        onClick={this.props.handleClick(false, this.props.data.ID)}>
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton aria-label="check" style={{color: "#008000"}}
                                        onClick={this.props.handleClick(true, this.props.data.ID)}>
                                <CheckIcon/>
                            </IconButton>
                        </Grid>
                    </CardActions>
                </Card>
            </div>
        )
    }
}