import axios from 'axios';
import {getIP} from '../Config/config'

class AuthorizationUtil {
    token = window.localStorage.getItem("token");
    header = {"Authorization": `Bearer ` + this.token};
    isLoggedIn = Boolean(this.token);
    getUserID(){
        return  axios.get(getIP() +'/getUserID/', {headers: this.header})
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }
    getOwnName() {
        return axios.get(getIP() +'/getOwnName/', {headers: this.header})
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getInferiors() {
        return axios.get(getIP() + '/getInferiors/', {headers: this.header})
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            })
    }

    getOpenCorrections() {
        return axios.get(getIP() + '/openCorrections/', {headers: this.header})
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log(error)
            })
    }

    postAbsence(start, end, reason){
        axios.post(getIP()+'/absence/', {start: start, end: end, reason: reason},{headers: this.header})
            .then((response) => {
        })
            .catch((error) => {
                console.log(error);
            })
    }

    resolveRelevantUserData(mID){
        return axios.post(getIP() + '/relevantUserData/', {mID: mID},{headers: this.header})
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log(error)
            })
    }

    resolveRelevantUserDataWithoutMID(){
        return axios.post(getIP() + '/relevantUserData/', {},{headers: this.header})
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log(error)
            })
    }
}
export default AuthorizationUtil