import React, {Component} from 'react'
import {Card, CardHeader, CardContent, Button} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';


export default class AdminPanel extends Component {

    styleButtons = () => {
        return {
            backgroundColor: '#ffca28'
        }
    }

    render() {
        return (
            <div style={{padding: '15px'}}>
                <Grid container direction={"column"} spacing={10} justify="space-evenly" alignItems="center">
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Card
                                    style={{minHeight: '15vh', minWidth: '80vh', color: '#fff', backgroundColor: '#016abf'}}>
                                    <Typography variant="h2" color={"initial"}> Adminpanel</Typography>
                                </Card>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item>
                        <Grid container direction={"row"} spacing={5} justify="space-evenly" alignItems="center"
                              style={{minHeight: '40vh'}}>
                            <Grid item>
                                <Card style={{minWidth: '40vh', color: '#fff', backgroundColor: '#016abf'}}>
                                    <CardHeader title="Übersicht"/>
                                    <CardContent>
                                        <Button
                                            style={this.styleButtons()}
                                            onClick={() => this.props.history.push('/overview')}>
                                            <SubdirectoryArrowRightIcon/>
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item>
                                <Card style={{minWidth: '40vh', color: '#fff', backgroundColor: '#016abf'}}>
                                    <CardHeader title="Nachträge"/>
                                    <CardContent>
                                        <Button
                                            style={this.styleButtons()}
                                            onClick={() => this.props.history.push('/nachtrag')}>
                                            <SubdirectoryArrowRightIcon/>
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item>
                                <Card style={{minWidth: '40vh', color: '#fff', backgroundColor: '#016abf'}}>
                                    <CardHeader title="Abwesenheiten"/>
                                    <CardContent>
                                        <Button
                                            style={this.styleButtons()}
                                            onClick={() => this.props.history.push('/absence')}>
                                            <SubdirectoryArrowRightIcon/>
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item>
                                <Card style={{minWidth: '40vh', color: '#fff', backgroundColor: '#016abf'}}>
                                    <CardHeader title="Überstunden"/>
                                    <CardContent>
                                        <Button
                                            style={this.styleButtons()}
                                            onClick={() => this.props.history.push('/overtime')}>
                                            <SubdirectoryArrowRightIcon/>
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item>
                                <Card style={{minWidth: '40vh', color: '#fff', backgroundColor: '#016abf'}}>
                                    <CardHeader title="Reporting"/>
                                    <CardContent>
                                        <Button
                                            style={this.styleButtons()}
                                            onClick={() => this.props.history.push('/report')}>
                                            <SubdirectoryArrowRightIcon/>
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
