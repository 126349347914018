import React, {Component} from 'react'
import AbsenceListItem from "./AbsenceListItem";
import Grid from "@material-ui/core/Grid";

export default class AbsenceList extends Component {
    render() {
        return (
            <Grid container justify="center" spacing={2} >
                {
                    this.props.data.map((d) => (
                        <Grid item>
                            <AbsenceListItem key={d.chipID} data={d} handleClick={this.props.handleClick}/>
                        </Grid>))
                }
            </Grid>)
    }


}