import React, { Component } from 'react'
import WorkerList from './WorkerList'
import { getIP } from '../../Config/config'
import AuthorizationUtil from "../../util/AuthorizationUtil";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const MySwitch = withStyles({
    switchBase: {
      color: green[0],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[1],
      },
    },
    checked: {},
    track: {},
  })(Switch);

export default class OvertimePage extends Component {

    auth = new AuthorizationUtil();


    state = {
        results: [],
        checkedA: false
    }

    _fetchData = () => {
        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => {
            this._handleResponse(xhr.response);
        })
        xhr.open('GET', getIP() + '/getOvertimes');
        //TODO: aus state holen
        xhr.setRequestHeader("Authorization", `Bearer ` + this.auth.token);
        xhr.send();
    }

    handleUpdate = (mID, h, m) => {
        var xhr = new XMLHttpRequest()
        xhr.addEventListener('load', () => {
            this._fetchData();
        })
        xhr.open('POST', getIP() + '/editOvertime')
        //TODO: aus state holen
        xhr.setRequestHeader("Authorization", `Bearer ` + this.auth.token);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify({ "mID": mID, "hours": h * -1, "minutes": m * -1 }));
    }

    _handleResponse = (response) => {
        this.setState({
            results: JSON.parse(response)
        })
    }

    componentDidMount() {
        this._fetchData();
    }
    handleChangeSwitch = (event) => {
        this.setState({ checkedA: !this.state.checkedA });
    };

    filterResults = () => {
        return this.state.results.filter((result) => {
            if (this.state.checkedA) {
                return result.isZeitarbeiter
            }
            else { return !result.isZeitarbeiter }
        })
    };

    render() {

        var pageTitle = this.state.checkedA ? "Stundenkonto" : "Überstunden";

        return (
            <div style={{ minHeight: '80vh' }}>
                <Grid container direction={"column"} style={{ minWidth: '100%', padding: '15px' }} alignItems={"center"}
                    justify={"space-evenly"} spacing={2}>

                    <Grid item>
                        <Paper style={{ padding: '5px', minWidth: '800px', color: '#fff', backgroundColor: '#016abf' }}>
                            <Typography variant="h2">
                                {pageTitle}
                            </Typography>
                        </Paper>
                    </Grid>
                    <FormControlLabel control={
                        <MySwitch checked={this.state.checkedA} onChange={this.handleChangeSwitch} value="checkedA" color= "ffffff"/>
                    } label="Zeitarbeiter" style={{color: '#ffffff'}} />
                    <Grid item>
                        <WorkerList data={this.filterResults()} handleUpdate={this.handleUpdate} subtitle={pageTitle} />
                    </Grid>
                </Grid>
            </div>
        )
    }
}
