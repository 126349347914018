import React, {Component} from "react";
import ReportComponent from "./ReportComponent";
import ReportSearchBar from "./ReportSearchBar";
import LoadContainer from "../../LoadContainer";
import Grid from "@material-ui/core/Grid";
import HolidayUtil from "../../util/HolidayUtil";

export default class ReportPage extends Component {

    holidayUtil = new HolidayUtil();

    state={
        results: [],
        workerData: null,
        holidays: [],
        loading: false
    };

    handleFetchResult = (results) => {
        this.setState({results: JSON.parse(results)});
    };

    handleLoad = (loading) => {
        this.setState({loading: loading});
    };

    handleWorkerData = (workerData) => {
        this.setState({workerData: workerData});
    }

    handleHolidays = (holidays) => {
        this.setState({holidays: holidays})
    }


    render() {
        const reportComponent = <ReportComponent report={this.state.results} workerData={this.state.workerData} holidays={this.state.holidays}/>;
        return(
            <div>
                <Grid container direction={"column"} alignItems={"center"} justify="center">
                <ReportSearchBar key={"report-page-searchbar"} handleWorkerData={this.handleWorkerData} handleLoad={this.handleLoad} handleFetch={this.handleFetchResult} handleHolidays={this.handleHolidays}/>
                <LoadContainer component={reportComponent} loading={this.state.loading}/>
                </Grid>
            </div>
        )
    }
}