import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {withStyles, makeStyles} from '@material-ui/styles';
import deLocale from '@fullcalendar/core/locales/de';
import AddEntryDialog from "./AddEntryDialog";
import axios from 'axios';
import LoadContainer from "./LoadContainer";
import AuthorizationUtil from "./util/AuthorizationUtil";
import {getIP} from './Config/config'
import format from 'date-fns/format'
import AddCorrectionDialog from "./AddCorrectionDialog";
import Divider from "@material-ui/core/Divider";
import HolidayUtil from "./util/HolidayUtil";

const useStyles = makeStyles({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

class CalendarComponent extends React.Component {

    auth = new AuthorizationUtil();
    holidayUtil = new HolidayUtil();


    calendarRef = React.createRef();

    changeMousePointerActive = () => {
        document.body.style.cursor = "pointer";
    };

    changeMousePointerPassive = () => {
        document.body.style.cursor = "default";
    };

    constructor(props) {
        super(props);
        this.state = {
            today: new Date(),
            viewMonth: null,
            events: [],
            corrections: [],
            addEntryDate: null,
            addEntryWholeDay: null,
            dayViewPopupVisible: false,
            displayInfo: null,
            displayDate: null,
            displayDateStart: null,
            displayDateLast: null,
            displayWorkedHours: null,
            addEntryDialogOpen: false,
            addCorrectionDialogOpen: false,
            currentEvent: null,
            loading: true,
            reload: false,
        }
    };

    fetchWorkDataForView = async () => {
        return axios.post(getIP() + '/report/', {
            start: "2019-12-01",
            end: "2020-12-31"
        }, {headers: {"Authorization": `Bearer ` + this.props.token}})
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    };

     fetchAbsenceDataForView = async ()=> {
         return axios.post(getIP() + '/reportAbsences/', {
             start: "2019-12-01",
             end: "2020-12-31"
         }, {headers: {"Authorization": `Bearer ` + this.props.token}})
             .then((response) => {
                 return response.data;
             })
             .catch((error) => {
                 console.log(error);
             });
    };

    loadData = async () => {

        let corrections = [];
        corrections = await this.auth.getOpenCorrections();
        let work = [];
        work = await this.fetchWorkDataForView() || [];
        for (let index = 0; index < work.length; index++) {
        }
        let events = [];
        work.forEach(date => {
            if (date.timeWorked > 0) {
                events.push({
                    title: 'Arbeit', date: date.date, color:'#016abf', extendedProps: {
                        timeWorked: date.timeWorked,
                        first: date.first,
                        last: date.last,
                        overTime: date.overTime,
                        breakTime: date.breakTime,
                    }
                })
            }});
        let absences = [];
        absences = await this.fetchAbsenceDataForView() || [];
        absences.forEach(date => {
            events.push({
                title: date.reason, date: date.date, color:'background:linear-gradient(to left, rgba(1, 106, 191, 1), rgba(47, 201, 253, 1))', extendedProps: {
                    first: date.start,
                    last: date.end
                }
            })
        });
        const holidays = await this.holidayUtil.getHolidaysForYear( new Date().getFullYear());
        holidays.forEach( holiday => {
            events.push({
                title: holiday.holidayDefinition._name, date: holiday.resolvedDate, color: '#53599a'

            })
        });
        this.setState({
            events: events,
            loading: false,
            corrections: corrections,
            addCorrectionDialogOpen: Boolean(corrections.length)
        })
    };

    showDetailedView = (date) => {
        this.setState({
            currentEvent: date,
            dayViewPopupVisible: true,
            anchorEl: date.el,
            displayInfo: date.event.title,
            displayDate: date.event.start.toDateString(),
            displayDateFirst: date.event.extendedProps.first,
            displayDateLast: date.event.extendedProps.last,
            displayWorkedHours: date.event.extendedProps.timeWorked,
            displayOvertime: date.event.extendedProps.overTime,
            displayBreakTime: date.event.extendedProps.breakTime,
        });
    };

    openAddEntryDialog = () => {
        this.setState({addEntryDialogOpen: true})
    };

    openAddCertainEntryDialog = (event) => () => {
        this.setState({addEntryDialogOpen: true, addEntryDate: event.event.start, addEntryWholeDay: event.event.allDay})
    };

    closeAddEntryDialog = () => {
        this.setState({addEntryDialogOpen: false, addEntryDate: null, addEntryWholeDay: null})
    };

    closeAddCorrectionDialog = () => {
        this.setState({addCorrectionDialogOpen: false})
    };

    componentDidMount() {
        this.loadData();
    }

    render() {
        const {classes} = this.props;
        const calendar = <FullCalendar ref={this.calendarRef} header={{
            left: 'title',
            center: '',
            right: 'custom1 prev next'
        }} customButtons={{
            custom1: {
                text: 'Abwesenheit ➕',
                click: this.openAddEntryDialog
            },
        }
        }
                                       displayEventTime={false} locale={deLocale} firstDay={1} fixedWeekCount={false}
                                       height={1100} eventClick={this.showDetailedView}
                                       eventMouseEnter={this.changeMousePointerActive}
                                       eventMouseLeave={this.changeMousePointerPassive} defaultView="dayGridMonth"
                                       plugins={[dayGridPlugin, interactionPlugin]} events={this.state.events}/>;
        const cardContent = <div spacing={2}>
            <Typography variant="h5" component="p">
                {this.state.displayInfo}
            </Typography>
            <Divider variant="middle" />
            <Typography className={classes.pos} color="textSecondary">
                Datum:
            </Typography>
            <Typography variant="body2" component="p">
                {format(new Date(this.state.displayDate), 'dd.MM.yyyy')}
            </Typography>
            {this.state.displayDateFirst && <Typography className={classes.pos} color="textSecondary">
                Start:
            </Typography>}
            <Typography variant="body2" component="p">
                {this.state.displayDateFirst !== undefined && this.state.displayDateFirst !== null ? format(new Date(this.state.displayDateFirst), 'HH:mm') + ' Uhr' : []}
            </Typography>
            {this.state.displayDateLast && <Typography className={classes.pos} color="textSecondary">
                Ende:
            </Typography>}
            <Typography variant="body2" component="p">
                {this.state.displayDateLast !== null && this.state.displayDateLast !== undefined ? format(new Date(this.state.displayDateLast), 'HH:mm') + ' Uhr' : []}
            </Typography>
            {this.state.displayWorkedHours && <div>
                <Typography className={classes.pos} color="textSecondary">
                    Dauer:
                </Typography>
                <Typography variant="body2" component="p">
                    {parseInt(this.state.displayWorkedHours / 3600) || 0} {"h "} {parseInt((this.state.displayWorkedHours % 3600) / 60) || 0} {" min"}
                </Typography>
            </div>}
            {Boolean(this.state.displayOvertime >= 0) && <div>
                <Typography className={classes.pos} color="textSecondary">
                    Überstunden:
                </Typography>
                <Typography variant="body2" component="p">
                    {parseInt(this.state.displayOvertime / 3600) || 0} {"h"}
                </Typography>
            </div>}
            {Boolean(this.state.displayBreakTime >= 0) && <div>
                <Typography className={classes.pos} color="textSecondary">
                    Pausenzeiten:
                </Typography>
                <Typography variant="body2" component="p">
                    {parseInt(this.state.displayBreakTime / 3600) || 0} {"h"}
                </Typography>
            </div>}
        </div>;

        return (
            <div className="App-body">
                <AddEntryDialog open={this.state.addEntryDialogOpen} onClose={this.closeAddEntryDialog}
                                date={this.state.addEntryDate} wholeDay={this.state.addEntryWholeDay}/>
                <AddCorrectionDialog open={this.state.addCorrectionDialogOpen} onClose={this.closeAddCorrectionDialog} corrections={this.state.corrections}/>
                <LoadContainer loading={this.state.loading} component={calendar}/>
                <Popover
                    open={this.state.dayViewPopupVisible}
                    onClose={this.handlePopupClose}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Card className={classes.card} >
                        <CardContent>
                            {cardContent}
                        </CardContent>
                    </Card>
                </Popover>
            </div>
        );
    }

    handlePopupClose = () => {
        this.setState({dayViewPopupVisible: false})
    }
}

export default withStyles(useStyles)(CalendarComponent);