import * as React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import {Typography} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {withStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import {withRouter} from "react-router-dom";
import AccountButton from "./AccountButton";
import {getIP} from './Config/config'
import AuthorizationUtil from "./util/AuthorizationUtil";
import Chip from "@material-ui/core/Chip";
import logo from './Logo_White_Ticamo_1_Quadrat.png';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';



const useStyles = makeStyles(theme => ({

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        align: "center"
    },
    appbar: {
        alignItems: 'center',
        width: '100%'
    }
}));

class Navbar extends React.Component {
    auth = new AuthorizationUtil();
    token = window.localStorage.getItem("token");

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            isAdmin: false
        }
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    handleLogout = () => {
        window.localStorage.clear();
        window.location.reload();
        this.props.history.push("/login");
    };


    fetchData = () => {
        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => this.setState({isAdmin: Boolean(JSON.parse(xhr.response).isAdmin)}));
        xhr.open('GET', getIP() + '/isAdmin');
        xhr.setRequestHeader("Authorization", this.token);
        xhr.send();
    }

    componentDidMount() {
        if (this.auth.isLoggedIn) {
            this.fetchData();
        }
    }

    handleLogoClick = () => {
        if (this.props.location.pathname !== "/") {
            this.props.history.push("/")
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <AppBar position="sticky" className={classes.appbar}>
                    <Toolbar className="App-title-bar">
                        <Grid
                            container
                            spacing={10}
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item style={{cursor: 'pointer', minWidth: '33.3%'}} onClick={this.handleLogoClick}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item> <img src={logo} alt="logo"
                                                     style={{height: "50px", width: "50px"}}/></Grid>
                                    <Grid item><Typography variant="h4"
                                                           className={classes.title}>TiCaMo</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{minWidth: '33.3%'}}>
                                {(this.props.isLoggedIn && this.state.isAdmin) && (
                                    <Chip className={"Chip"} color="primary"
                                          label={this.props.location.pathname === "/admin" ? "Kalender 📅" : "Dashboard 🗄️"}
                                          onClick={() => {
                                              if (this.props.location.pathname === "/admin") {
                                                  this.props.history.push('/')
                                              } else {
                                                  this.props.history.push('/admin')
                                              }
                                          }}/>
                                )}
                            </Grid>
                            <Grid item style={{minWidth: '33.3%'}}>
                                <Grid container spacing={2} alignItems="center" justify="flex-end">
                                    <Grid item>
                                {this.props.isLoggedIn && (
                                    <AccountButton anchorEl={this.state.anchorEl} onClick={this.handleMenu}
                                                   onClose={this.handleClose} onLogout={this.handleLogout}/>
                                )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}
export default (withStyles(useStyles)(withRouter(Navbar)));