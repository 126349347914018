import * as React from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from '@date-io/date-fns';
import isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField from "@material-ui/core/TextField";
import AuthorizationUtil from "./util/AuthorizationUtil";
import de from 'date-fns/locale/de'

class AddEntryDialog extends React.Component {
    auth = new AuthorizationUtil();

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            entry: null,
            wholeDay: false,
            datesCorrect: true,
            dateErrorText: "",
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null,
            workStart: null,
            workEnd: null,
            comment: "",
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const data = await this.auth.resolveRelevantUserDataWithoutMID();
        this.setState({workStart: data[0].Arbeitszeitstart, workEnd: data[0].Arbeitszeitende});
    };

    handleChange = name => event => {
        this.setState({
            [name]: event && event.target && event.target.value,
        });
    };

    handleChecked = name => event => {
        this.setState({
            [name]: event && event.target && event.target.checked,
        });
    };

    handleDateChange = name => date => {
        this.setState({dateErrorText: ""});
        this.setState({
            [name]: date
        });
    };

    isValid = (start, end) => {
        if (isBefore(parseISO(end), parseISO(start))) {
            this.setState({dateErrorText: "Die Enddaten müssen vor den Startdaten liegen."});
            return false;
        }
        return true;
    };

    handleSubmit = () => {
        const parseDateFromDateAndTime = (date, time) => {
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), parseInt(time.substr(0, 2)), parseInt(time.substr(3, 2)), parseInt(time.substr(6, 2)));
        };
        const wholeDay = this.state.wholeDay || this.props.wholeDay;
        const startDate = this.state.startDate || this.props.date;
        let startTime = null;
        let endDate = null;
        let endTime = null;

        if (!wholeDay) {
            startTime = this.state.startTime;
            endDate = this.state.endDate;
            endTime = this.state.endTime;
        } else {
            startTime = parseDateFromDateAndTime(startDate, this.state.workStart);
            endTime = parseDateFromDateAndTime(startDate, this.state.workEnd);
            endDate = startDate;
        }
        const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime.getHours(), startTime.getMinutes(), startTime.getSeconds()).toString();
        const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime.getHours(), endTime.getMinutes(), endTime.getSeconds()).toString();
        const reason = this.state.comment;
        const valid = this.isValid(start, end);
        if (valid) {
            this.auth.postAbsence(start, end, reason);
            this.handleClose();
        }
    };

    handleClose = () => {
        this.setState({startDate: null, endDate: null, startTime: null, endTime: null, comment: "", dateErrorText: ""});
        this.props.onClose();
    };


    render() {
        const valid = (this.state.wholeDay && this.state.startDate && this.state.comment) || ((this.state.startDate || this.props.date) && this.state.endDate && this.state.startTime && this.state.endTime && this.state.comment);
        return (<div>
            <Dialog open={this.props.open} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Abwesenheit {<span role="img" aria-labelledby="plus emoji">➕</span>}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Hier können Abwesenheiten eingetragen werden.
                    </DialogContentText>
                    <Grid container
                          spacing={2}
                          direction="column">
                        <Grid item>
                            <FormControlLabel
                                control={<Checkbox checked={this.props.wholeDay || this.state.wholeDay}
                                                   label="Ganztägiger Eintrag"
                                                   value="wholeDay"
                                                   onChange={this.handleChecked("wholeDay")}/>}
                                label="Eine Ganztägige Abwesenheit eintragen."/>
                        </Grid>
                        {(this.props.wholeDay || this.state.wholeDay) && <Grid item>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                                <KeyboardDatePicker
                                    required="true"
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Datum des Eintrags"
                                    format="dd/MM/yyyy"
                                    value={this.props.date || this.state.startDate}
                                    onChange={this.handleDateChange("startDate")}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>}

                        {!(this.props.wholeDay || this.state.wholeDay) && <Grid item>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                                    <KeyboardDatePicker
                                        required="true"
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Startdatum des Eintrags"
                                        format="dd/MM/yyyy"
                                        value={this.props.date || this.state.startDate}
                                        onChange={this.handleDateChange("startDate")}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    <KeyboardTimePicker
                                        required="true"
                                        margin="normal"
                                        id="time-picker"
                                        label="Startzeitpunkt des Eintrags"
                                        value={this.state.startTime}
                                        onChange={this.handleDateChange("startTime")}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                                    <KeyboardDatePicker
                                        required="true"
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Enddatum des Eintrags"
                                        error={this.state.dateErrorText}
                                        helperText={this.state.dateErrorText}
                                        format="dd/MM/yyyy"
                                        value={this.state.endDate}
                                        onChange={this.handleDateChange("endDate")}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    <KeyboardTimePicker
                                        required="true"
                                        margin="normal"
                                        id="time-picker"
                                        label="Endzeitpunkt des Eintrags"
                                        error={this.state.dateErrorText}
                                        helperText={this.state.dateErrorText}
                                        value={this.state.endTime}
                                        onChange={this.handleDateChange("endTime")}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>}
                        <Grid item>
                            <TextField id="standard-basic" label="Kommentar" value={this.state.comment} helperText={this.state.commentErrorText}
                                       onChange={this.handleChange("comment")} required="true"/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button disabled={!valid} onClick={this.handleSubmit} color="primary">
                        Senden
                    </Button>
                </DialogActions>
            </Dialog>
        </div>)
    }

}

export default AddEntryDialog