export default (timestamp) => {
    var date = new Date(timestamp);
    var hour = String(date.getHours())
    var min = String(date.getMinutes())
    
    if (hour.length < 2){
        hour = "0" + hour
    };
    if (min.length < 2){
        min = "0" + min
    };
    var time = hour + ":" + min + " Uhr";
    return time;
}