import React from 'react';
import './Login.css';
import './main.scss'
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { getIP } from './Config/config'


class NewPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: null,
            password: null,
        }
    };

    safeNewPassword = () => {
        let token = window.localStorage.getItem("token");
        let newpassword = document.getElementById("password").value;
        let newpassword2 = document.getElementById("password2").value;

        if (newpassword !== newpassword2) {
            alert("Änderung fehlgeschlagen: die Passwörter stimmen nicht überein.")
            window.location.reload();
            return;
            
        }
                
        // create a new XMLHttpRequest
        var xhr = new XMLHttpRequest()

        // get a callback when the server responds
        xhr.addEventListener('load', () => {
            // update the state of the component with the result here
            if (xhr.responseText !== "OK") {
                alert("Änderung fehlgeschlagen!")
                window.location.reload();
            }
            else {
                alert("Änderung war erfolgreich!")
                this.props.history.push('/');
            }

        });

        // open the request with the verb and the url
        xhr.open("POST", getIP() + '/newPassword');
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Authorization", token);
        // send the request
        xhr.send(JSON.stringify({ "newpassword": newpassword }));
    };

    render() {
        return (
            <div className="login-form">
                <div className="form-group">
                    <Typography variant="h1">Neues Passwort</Typography>
                    <h2>Anforderungen:</h2>
                    <h3>Groß- und Kleinbuchstabe sowie Zahl.</h3>
                    <label>
                        <input type="password" name="psw" placeholder="Neues Passwort" id="password" pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$"/>
                    </label>
                    <span className="input-icon"><i className="fa fa-lock"></i></span>

                </div>
                <div className="form-group">
                    <label>
                        <input type="password" name="psw2" placeholder="Neues Passwort wiederholen" id="password2" pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$"/>
                    </label>
                    <span className="input-icon"><i className="fa fa-lock"></i></span>
                </div>
                <Button className="login-btn" onClick={this.safeNewPassword}>Passwort ändern</Button>
            </div>);
    }
}

export default NewPassword;



