import React, {Component} from 'react'
import ResultListElement from './ResultListElement';
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";

export default class ResultList extends Component {
    render() {
        return (
            <List style={{maxHeight: 550, overflow: 'auto'}}>
                <Grid container justify="center" >
                    {this.props.results.map((res) => (
                        <Grid item>
                            <ResultListElement key={res.date} result={res}/>
                        </Grid>))}
                </Grid>
            </List>
        );
    }
}
