import React, { Component } from 'react'
import AuthorizationUtil from "../../util/AuthorizationUtil";
import {getIP} from "../../Config/config";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AbsenceList from "./AbsenceList";

export default class AbsencePage extends Component {

    auth = new AuthorizationUtil();

    state = {
        results: []
    }

    handleResponse = (response) => {
        this.setState({
            results: JSON.parse(response)
        })
    }

    handleClick = (isAccepted, ID) => e => {
        var xhr = new XMLHttpRequest()
        xhr.addEventListener('load', () => {
        })
        xhr.open('POST', getIP() + '/answerAbsence');
        xhr.setRequestHeader("Authorization", `Bearer ` + this.auth.token);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify({"answer": isAccepted, "ID": ID}));
        this.fetchData();
    }

    fetchData = () => {
        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => {
            this.handleResponse(xhr.response);
        })
        xhr.open('GET', getIP() + '/getToAllowAbsence');
        //TODO: aus state holen
        xhr.setRequestHeader("Authorization", `Bearer ` + this.auth.token);
        xhr.send();
    }

    render() {
        return (
            <div style={{minHeight: '80vh'}}>
                <Grid container direction={"column"} style={{minWidth: '100%', padding: '15px'}} alignItems={"center"}
                      justify={"space-evenly"} spacing={2}>
                    <Grid item>
                        <Paper style={{padding: '5px', minWidth: '800px', color: '#fff', backgroundColor: '#016abf'}}>
                            <Typography variant="h2">
                                Offene Anträge auf Abwesenheit:
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item>
                        {Boolean(this.state.results.length)?
                            <AbsenceList data={this.state.results} handleClick={this.handleClick}/> :
                            <Paper style={{color: '#fff', backgroundColor: '#016abf'}}>
                                <Typography variant="h4">
                                    Momentan sind keine Einträge vorhanden.
                                </Typography>
                            </Paper>}
                    </Grid>
                </Grid>
            </div>
        )
    }

    componentDidMount() {
        this.fetchData();
    }

}