import React from 'react';
import './Login.css';
import './main.scss'
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { getIP } from './Config/config'


class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: null,
            password: null,
        }
    };

    checkLogin = () => {
        let username = document.getElementById("username").value;
        let password = document.getElementById("password").value;

        // create a new XMLHttpRequest
        var xhr = new XMLHttpRequest()

        // get a callback when the server responds
        xhr.addEventListener('load', () => {
            // update the state of the component with the result here
            if (xhr.responseText === "Not Found") {
                alert("Login fehlgeschlagen - Nutzer nicht vorhanden")
                this.props.history.push('/');
                window.location.reload();
            }
            else if (xhr.responseText === "Unauthorized") {
                alert("Login fehlgeschlagen - Passwort falsch")
                this.props.history.push('/');
                window.location.reload();
            } else {
                //safe in localStorage
                var token = xhr.responseText.substring(10)
                window.localStorage.setItem('token', token.substring(0, token.length - 2));
                alert("Login erfolgreich!")
                this.props.history.push('/');
                window.location.reload();

            }
        });

        // open the request with the verb and the url
        xhr.open("POST", getIP() + '/login');
        xhr.setRequestHeader("Content-Type", "application/json");
        // send the request
        xhr.send(JSON.stringify({ "username": username, "password": password }));
    };

    // Login with key press
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.checkLogin();
        }
    };

    render() {
        return (
            <div className="login-form">
                <div className="form-group">
                    <Typography variant="h1">TiCaMo</Typography>
                    <label>
                        <input type="username" name="username" placeholder="Nutzername" id="username" />
                    </label>
                    <span className="input-icon"><i className="fa fa-envelope"></i></span>

                </div>
                <div className="form-group">
                    <label>
                        <input type="password" name="psw" placeholder="Passwort" id="password" onKeyDown={this._handleKeyDown} />
                    </label>
                    <span className="input-icon"><i className="fa fa-lock"></i></span>
                </div>
                <Button className="login-btn" onClick={this.checkLogin}>Login</Button>
            </div>);
    }
}

export default Login;