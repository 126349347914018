import React, { Component } from 'react'
import WorkerListItem from './WorkerListItem'
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default class WorkerList extends Component {
    render() {
        return (
            <Paper style={{ width: '800px', color: '#fff', backgroundColor: '#016abf' }}>
                <List>
                    <Grid container justify="center">
                        <Grid item>
                            <Paper elevation="0" style={{
                                color: '#fff', backgroundColor: '#016abf',
                                minWidth: '700px',
                                padding: '1px',
                                textAlign: 'center'
                            }}>
                                <Grid container alignItems={"center"}
                                    justify={"space-evenly"}>
                                    <Grid item style={{ width: '80px' }}>
                                        <Typography>Mitarbeiter</Typography>
                                    </Grid>
                                    <Grid item style={{ width: '80px' }}>
                                        <Typography>{this.props.subtitle}</Typography>
                                    </Grid>
                                    <Grid item style={{ width: '80px' }}>
                                        <Typography>Ausgleichen</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item>
                            {this.props.data.map((d) =>
                                <WorkerListItem key={d.MitarbeiterID} data={d} handleUpdate={this.props.handleUpdate} />
                            )}
                        </Grid>
                    </Grid>
                </List>
            </Paper>
        )
    }
}
