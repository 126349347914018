import React, { Component } from 'react'
import CorrectionListItem from './CorrectionListItem'

export default class CorrectionList extends Component {
    render() {
        return this.props.data.map((d) =>
            <CorrectionListItem key={d.chipID} data={d} handleClick={this.props.handleClick} />
        )
    }
}
