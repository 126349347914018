import React, {Component} from 'react'
import Searchbar from './SearchBar';
import ResultList from './ResultList';
import ResultCard from './ResultCard';
import formatDate from '../../util/formatDate';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

export default class OverviewPage extends Component {
    state = {
        results: [],
        total: {
            first: "",
            last: ""
        }
    }

    handleFetchResult = (results) => {
        this.setState({results: JSON.parse(results)})
        this.calcTotal();
    }

    calcTotal = () => {
        var res = this.state.results;
        var dateFirst = new Date(res[0].date);
        var dateLast = new Date(res[res.length - 1].date);
        var otTotal = 0;
        res.forEach(record => {
            otTotal += record.overTime;
        });
        this.setState({
            total: {
                first: formatDate(dateFirst),
                last: formatDate(dateLast),
                overTime: otTotal
            }
        });
    }


    render() {
        return (
                <Grid container direction={"column"} style={{width: '100%'}} alignItems={"center"} justify="space-around">
                    <Grid item>
                        <Searchbar handleFetch={this.handleFetchResult}/>
                    </Grid>
                    <Grid item>
                      {this.state.results.length > 0 && (<Paper style={{width: '1000px', color: '#fff', backgroundColor: '#016abf'}}>
                            <ResultCard result={this.state.total}/>
                            <ResultList results={this.state.results}/>
                        </Paper>)}
                    </Grid>
                </Grid>
        )
    }
}
