import React, {Component} from 'react';
import CorrectionList from './CorrectionList';
import {getIP} from '../../Config/config'
import AuthorizationUtil from "../../util/AuthorizationUtil";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

export default class CorrectionPage extends Component {
    auth = new AuthorizationUtil();

    state = {
        results: []
    }

    handleResponse = (response) => {
        this.setState({
            results: JSON.parse(response)
        })
    }

    handleClick = (isAccepted, chipID, timestamp) => e => {
        this.setState({results: this.state.results.filter(elem => elem.chipID !== chipID)})

        var xhr = new XMLHttpRequest()
        xhr.addEventListener('load', () => {
            console.log(xhr.response)
        })
        xhr.open('POST', getIP() + '/answerCorrection');
        xhr.setRequestHeader("Authorization", `Bearer ` + this.auth.token);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify({"answer": isAccepted, "chipID": chipID, "timestamp": timestamp}));
        this.fetchData();

    }

    fetchData = () => {
        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => {
            this.handleResponse(xhr.response);
        })
        xhr.open('GET', getIP() + '/getToCorrect');
        //TODO: aus state holen
        xhr.setRequestHeader("Authorization", `Bearer ` + this.auth.token);
        xhr.send();
    }

    render() {
        return (
            <div style={{minHeight: '80vh'}}>
                <Grid container direction={"column"} style={{minWidth: '100%', padding: '15px'}} alignItems={"center"}
                      justify={"space-evenly"} spacing={2}>
                    <Grid item>
                        <Paper style={{padding: '5px', minWidth: '800px' , color: '#fff', backgroundColor: '#016abf'}}>
                            <Typography variant="h2">
                                Offene Nachträge:
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item>
                        {Boolean(this.state.results.length)?
                        <CorrectionList data={this.state.results} handleClick={this.handleClick}/> :
                        <Paper style={{color: '#fff', backgroundColor: '#016abf'}}>
                            <Typography variant="h4">
                                Momentan sind keine Einträge vorhanden.
                            </Typography>
                        </Paper>}
                    </Grid>
                </Grid>
            </div>
        )
    }

    componentDidMount() {
        this.fetchData();
    }
}
